import React from "react";
import BasicRating  from "./rating";
import Syllabus from "./syllabus"
import '../Components/course_box.scss'
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import noIcon from '../Assets/Images/logo192.png';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ShareButton from "./share_buttons";

const baseUrl = process.env.REACT_APP_BASE_URL;


const PrvtCourseBox = (props) =>{

  const navigate = useNavigate();

  const shareText = `🚀 הזדמנות שלא מפספסים! 🌐 קורס אונליין בנושא ${props.subject} 📚 עם ${props.teacher}, אחד המורים המובילים בתחום. 💡 תן לעצמך את הכלים להצלחה והרשם עכשיו! 🔑`;
  const courseLink = `🔗 לחץ כאן להרשמה: https://www.doroosonline.co.il/search?code=${props.code}&mode=PRIVATE`;

  const courseRegistration= async ()=>{
        
    if(props.user==="DEFAULT"){
      navigate('/login',{state:{code:props.code,mode:'PRIVATE'}})
    }
    else {
      try {
            const response = await axios.get(`${baseUrl}/course_reg.php`,{
              params: {                        
                code:props.code,
                stdId:props.stdId                                              
              }
            })

            if(response.data.status==='success'){
              props.alert(response.data.message,'SUCCESS')
              props.updateCourseStatus(props.index,props.code)
            }
            else{
              throw new Error(response.data.message || 'An unknown error occurred');
            }

      } 
      catch (error) {

        props.alert(error.message,'ERROR') ;
        
      }
    }
  }
//-------------------------------------------------------------------------------------------

  const toClassRoom = () => {
   
    if(props.user==="TCH" || props.user==="SCH")
      navigate('/tchclassroom',{
        state:{
                code:props.code,
                xcode:props.xcode,
                user:props.user,
                mode:'PRIVATE',
                subject:props.subject
              }
        })
    else if(props.user==="STD")
      navigate('/stdclassroom',{
        state:{
                code:props.code,
                xcode:props.xcode,
                user:props.user,
                mode:'PRIVATE',
                subject:props.subject
              }
      })
  }
//------------------------------------------------------------------------------------------- 

  const toCoordination = () => {
   
    navigate('/privatemeetings',{
      state:{
              stdId:props.stdId,
              code:props.xcode,
              tchId:props.tchId,
              price:props.price,
              teacher:props.teacher,
              subject:props.subject, 
              photo:props.photo,
            }
    })

  }
//------------------------------------------------------------------------------------------- 
  return (
    <div className="course-container" style={{background:props.bgcolor}}>
      <div className="course-box">
            
        <div className="subject-icon-container">
          <div className="frame">
              <img 
                  src={props.photo != null ? props.photo : noIcon} 
                  alt="subject icon" 
                  className="subject-icon" 
              />
          </div>
        </div>

        <div className="syllabus-container">
          <div className="syllabus-subject">
            <p className="subject-title">{props.subject}</p>
            <Syllabus  
              className="syllabus-link" 
              btn_txt="לפרטים מלאים" 
              syllabus={props.syllabus}
              alert={props.alert}
            />        
          </div>
        </div>

        <div className="rating-container">
          <div className="teacher-rating">
            <p className="teacher-name">{props.teacher}</p>          
              <span dir="ltr">
                <BasicRating 
                  value={props.rating} 
                  ratings={props.ratings} 
                  readOnly={true} 
                  alert={props.alert}
                />
              </span>           
          </div>
        </div>

        
          {props.search?
            <>
              {props.reg!==null?
                <div>
                    <button 
                      type="button" 
                      class="btn btn-primary btn-sm" 
                      style={{width:"70px", borderRadius: "20px" }} 
                      disabled
                    >
                      רשום
                    </button> 
                </div>
                :
                <div>
                    <button 
                      type="button" 
                      class="btn btn-success btn-sm" 
                      style={{width:"70px", borderRadius: "20px" }} 
                      onClick={courseRegistration}
                      >
                        הרשמה
                      </button> 
                </div> 
              }
            </> 
            :
            <>
              {props.user==='STD'?
                <div>
                    <button 
                      type="button" 
                      class="btn btn-warning btn-sm" 
                      style={{width:"110px", borderRadius: "20px" }} 
                      onClick={toCoordination}
                      disabled={props.status=="ARCHIVED"}
                    >
                      תיאום מפגשים
                    </button> 
                </div>
                :
                null
              }
              <div>                    
                  {props.status!=="EDITING"?
                    <button 
                      type="button" 
                      className="btn btn-success btn-sm" 
                      style={{width:"70px", borderRadius: "20px" }} 
                      onClick={toClassRoom}
                    >
                      מפגשים
                    </button> 
                    :
                    <button 
                      type="button" 
                      className="btn btn-success btn-sm" 
                      style={{width:"70px", borderRadius: "20px" }} 
                      disabled
                    >
                      מפגשים
                    </button> 
                  } 
              </div>
            </>
          }         
      </div> 

      <div className="position-absolute" style={{top:"-4px",left:"-2px"}}>  
        {props.children}
      </div>

      <div className="position-absolute" style={{ [props.user === 'STD'||props.search || props.status==="CLOSED" ? 'top' : 'bottom']: 1, left: 3 }}>
        <div className="d-flex flex-column align-items-center">
          <ShareButton 
            courseLink={courseLink} 
            shareText={shareText}
            alert={props.alert}
          />
          <PermIdentityIcon style={{ color: '#9e9e9e' }} />          
        </div>
      </div>   
    </div>                                                 
  );
};
export default PrvtCourseBox;



