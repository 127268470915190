import '../Layouts/footer.css'
import { TiSocialFacebook } from 'react-icons/ti';
import { AiOutlineGooglePlus } from 'react-icons/ai';
import { TiSocialTumbler } from 'react-icons/ti';
import { ImYoutube2 } from 'react-icons/im';
import { AiOutlineInstagram } from 'react-icons/ai';
import Divider from '../Components/divider';


const Footer = () => {
    return(
        
        <footer>
            <div class="d-flex flex-column align-items-center">
                                               
                <div className="social d-flex gap-3 mt-2">                                        
                    <a href="#"><AiOutlineGooglePlus /></a>
                    <a href="#"><TiSocialTumbler /></a>
                    <a href="#"><ImYoutube2 /></a>
                    <a href="#"><AiOutlineInstagram /></a>   
                    <a href="#"><TiSocialFacebook /></a>                
                </div>
                                                                                    
                <div className="f-bar d-flex gap-2 mt-3 mb-3">
                    <a href="/about" className="text-decoration-none" style={{ color: 'white' }}>מי אנחנו</a>
                    <span>|</span>
                    <a href="/terms" className="text-decoration-none" style={{ color: 'white' }}>תנאי שימוש</a>
                    <span>|</span>
                    <a href="/privacy_policy" className="text-decoration-none" style={{ color: 'white' }}>מדיניות פרטיות</a>
                    <span>|</span>
                    <a href="/contact" className="text-decoration-none" style={{ color: 'white' }}>צור קשר</a>
                </div>

                                
                <Divider color="rgb(182, 182, 182,0.3)" height="2px"/> 
                                                                                               
                <div className="mt-3 mb-2">                                                                     
                    <h5>&copy; doroosonline.co.il</h5>
                </div>                
               
            </div> 
        </footer>       
    );
  };
  
  export default Footer;