import React from 'react';
import { useCountdown } from './use_count_down';

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div dir='ltr'>            
      {days} : {hours} : {minutes} : {seconds}                           
    </div>
  );
};

const CountdownTimer = (props) => {
  const [days, hours, minutes, seconds] = useCountdown(props.targetDate);

  if (days + hours + minutes + seconds <= 0) {
    props.countdownStopped()
  } 
  else {
    return (
      <button type="button" class="btn btn-warning btn-sm" style={{borderRadius:"20px",width:"130px"}} disabled>                                              
        <span>
          <ShowCounter 
            days={days} 
            hours={hours} 
            minutes={minutes} 
            seconds={seconds}
          />
        </span>                                                                                                
      </button>                                          
    );
  }
};

export default CountdownTimer;
