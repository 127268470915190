import React, { useState,useEffect} from "react";
import {Modal} from 'react-bootstrap'; 
import Button from '@mui/material/Button'; 
import "react-datepicker/dist/react-datepicker.css";
import './popup_modals.css';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { replyToMsg } from "./db";
import * as Yup from 'yup';
import '../Components/forms.css'; 
import ReactLoading from 'react-loading';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;


const emailSchema = Yup.object().shape({

    subject: Yup.string()
        .min(5, 'Subject must be at least 5 characters')
        .required('Subject is required'),

    content: Yup.string()
        .min(5, 'Content must be at least 5 characters')
        .required('Content is required'),
    
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
});
 
//------------------------------------------------------------------------------------------- 

const ReplyToMsg=(props)=>{ 

    const [fire,setFire]=useState(false);
    const [errors, setErrors] = useState({});
    const [show,setShow]=useState(false);
    
    const [replyMsgDtls, setReplyMsgDtls] = useState({
        email:props.email,
        subject:props.subject,
        content:'',           
    });
    
//------------------------------------------------------------------------------------------- 

    useEffect(()=>{        
        if(!props.link)
            setShow(true);
    },[]);
//------------------------------------------------------------------------------------------- 

    const alert=(msg,status)=>{
        closeReplyToMsg()
        props.alert(msg,status)
    }
//------------------------------------------------------------------------------------------- 
        
    const handleChange = event => {
        var { name, value } = event.target;
        
        setReplyMsgDtls(prevState => ({
            ...prevState,
            [name]: value
        }));   
    };
//------------------------------------------------------------------------------------------- 
            
const handleSubmit = async (event) => {
    event.preventDefault();

    try {
        // Validate the input form data using the schema
        await emailSchema.validate(replyMsgDtls, { abortEarly: false });
        setFire(true);

        const currentDate = new Date();
        const replyDate = `<div style="font-size:x-small">${currentDate.toISOString().slice(0, 19).replace('T', ' ')}</div>`;

        const msgDate = `<div style="font-size:x-small">${props.date}</div>`;

        let msg;
        if (props.status !== 'Sent') {
            msg = props.msg.replace("<split>", "<br><br>") + msgDate;
        } else {
            msg = props.msg.replace("<split>", "<br><br>");
        }

        const reply = replyMsgDtls.content.replace(/\n/g, "<br>") + replyDate;

        try {
            // Make the GET request to the updated API endpoint
            const response = await axios.get(`${baseUrl}/sendEmail.php`, {
                params: {
                    subject: replyMsgDtls.subject,
                    email: replyMsgDtls.email,
                    msg:msg,
                    reply:reply,
                },
            });

            // Handle the API response
            if (response.data.status === 'success') {
                const msg1 = `${msg}<split>${reply}`;
                const code = await replyToMsg(props.msgId, msg1);

                if (code > 0) {
                    alert("דואר אלקטרוני נשלח ליעד בהצלחה!!", 'SUCCESS');
                    props.updateMessage(props.index, msg1);
                } else {
                    alert("שמירת ההודעה לא הושלמה בהצלחה!!", 'ERROR');
                }
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || "An unknown error occurred";
            alert(errorMessage, 'ERROR');
        } finally {
            setFire(false);
            setReplyMsgDtls({
                email: '',
                subject: '',
                content: '',
            });
            setErrors({});
        }
    } catch (err) {
        setErrors(err.inner.reduce((acc, { path, message }) => {
            acc[path] = message;
            return acc;
        }, {}));
    }
};

//------------------------------------------------------------------------------------------- 

    const openReplyToMsg = () => { 
        setShow(true);
    }
//------------------------------------------------------------------------------------------- 

    const closeReplyToMsg = () => { 

        setShow(false);

        setErrors({})

        if(!props.link)
            props.resetMenu(0);
    }
//------------------------------------------------------------------------------------------- 

    return (  
        <div>        
            {props.link?
                <>  
                    <Button 
                        variant="contained" 
                        color="success" 
                        size="small" 
                        onClick={openReplyToMsg}
                        disabled={props.status==='Sent'}
                    >
                        כתוב תגובה
                    </Button>                                                                                   
                </>
                :null
            }           
                        
            <Modal 
              show={show}  
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"            
             >  

                <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><FaChalkboardTeacher size={30} /></div>
                        <div className="mt-1"><h5>כתוב תגובה</h5></div>
                    </div> 
                    <button
                        type="button"
                        className="custom-modal-close"
                        aria-label="Close"
                        onClick={closeReplyToMsg}
                    >
                        &times;  {/* Close "X" icon */}
                    </button>                                             
                </Modal.Header>  
                
                <form onSubmit={handleSubmit}>
                    <Modal.Body> 
                    <div className="container">
                        <div class="position-relative d-flex flex-column gap-4 mt-4">

                            {fire?
                                <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex: "1"}}>
                                    <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
                                </div>
                                :
                                null
                            }
                                                                                                                        
                            <div className="label-100-wrap">                
                                <label className="form-label label-100" for="subject">שלח אל</label>
                                <div>
                                    <input  
                                        type="text" 
                                        className={`form-control ${errors.email? 'invalid' : ''}`} 
                                        name="email" 
                                        value={replyMsgDtls.email} 
                                        onChange={handleChange}
                                        readOnly                                         
                                    />
                                    {errors.email && <p className="error">{errors.email}</p>}
                                </div>   
                            </div>

                            <div className="label-100-wrap mt-3">                
                                <label className="form-label label-100" for="subject">נושא</label>
                                <div>
                                    <input  
                                        type="text" 
                                        className={`form-control ${errors.subject? 'invalid' : ''}`} 
                                        name="subject" 
                                        value={replyMsgDtls.subject} 
                                        onChange={handleChange} 
                                        readOnly                                       
                                    />
                                    {errors.subject && <p className="error">{errors.subject}</p>}
                                </div>   
                            </div>

                            <div  className="label-100-wrap mt-3">                
                                <label className="form-label label-100" for="content">הודעה</label>
                                <div dir="rtl">
                                    <textarea 
                                        className={`form-control ${errors.content? 'invalid' : ''}`} 
                                        name="content"   
                                        rows="4" 
                                        value={replyMsgDtls.content} 
                                        onChange={handleChange}                                       
                                    />
                                    {errors.content && <p className="error">{errors.content}</p>}                           
                                </div>   
                            </div> 
                        </div>                                                
                    </div>
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3 me-3 mb-2">                         
                            <Button variant="contained"  color="error"  size="small" onClick={closeReplyToMsg}>ביטול</Button>  
                            <Button type="submit" variant="contained" color="success" size="small" >שלח תגובה</Button>                         
                        </div>
                    </Modal.Footer> 
                </form>        
            </Modal> 
        </div>        
    )  
  } 
  
export default ReplyToMsg;
