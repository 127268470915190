import React, { useState,useEffect,useCallback} from "react";
import {Modal} from 'react-bootstrap';
import Button from '@mui/material/Button'; 
import DatePicker from "react-datepicker";
import dateFormat, { masks } from "dateformat";
import "react-datepicker/dist/react-datepicker.css";
import { FaChalkboardTeacher } from 'react-icons/fa';
import './popup_modals.css';
import SelectCategory from "./select_category";
import SelectSubject from "./select_subject";
import SelectTeacher from "./select_teacher";
import CourseMode from "./set_course_mode";
import { addDays } from "./time"
import { newCourse,updateCourse } from "./db";
import * as Yup from 'yup';
import '../Components/forms.css'
import axios from 'axios';
import ReactLoading from 'react-loading';

const baseUrl = process.env.REACT_APP_BASE_URL;

const loginSchema = Yup.object().shape({

    name: Yup.string()
        .min(5, 'Subject must be at least 5 characters')
        .max(40, 'Subject must be at most 40 characters')
        .required('Subject is required'),
    
    price: Yup.number()
        .required('Price is required')
        .positive('Price must be a positive number')
        .max(1000,'Price must be less than 1000')
        .min(10,'Price must be more than 10')
        .typeError('Please enter a valid price'),

    date: Yup.date()
        .required("Date is required")
        .typeError('Please enter a valid date'),
              
});
   
const CourseModal=(props)=>{ 

    const [errors, setErrors] = useState({});
    const [show,setShow]=useState(false);     
    const [fire,setFire]=useState(false);   
    const [courseDtls, setCourseDtls] = useState({        
        price:"",        
        name:"",
        subId:0,
        catId:0,
        tchId:0,
        teacher:"",
        mode:"GROUP",
        date:addDays(new Date(), 1)
    });
//-------------------------------------------------------------------------------------------    

    useEffect(()=>{

        const fetchCourse = async () => {
            try {
                const response = await axios.get(`${baseUrl}/get_course_dtls.php`,{
                    params: {
                        code:props.code
                    }
                })
                if(response.data.code>0){
                    setCourseDtls({
                        ['price']:response.data.price,
                        ['name']:response.data.subject,
                        ['subId']:response.data.subId,
                        ['catId']:response.data.catId,
                        ['tchId']:response.data.tchId,
                        ['mode']:response.data.mode,
                        ['teacher']:response.data.teacher,
                        ['date']: response.data.mode === "PRIVATE" ? addDays(new Date(), 1) : new Date(response.data.date)
                    })
                }                                
            } 
            catch (error) {        
                alert(error.message,'ERROR') ;                
            }            
        }

        if(props.editing)
            fetchCourse()
                                            
        if(!props.link)
            setShow(true);

    },[]);
//-------------------------------------------------------------------------------------------    

    const alert = (msg,status) => {

        closeCourseModal();
        props.alert(msg,status)
    }
//-------------------------------------------------------------------------------------------    
    const handleChange = e => {
        const { name, value } = e.target;
        setCourseDtls(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
//-------------------------------------------------------------------------------------------

    const handleSubmit = async (event) => {
                        
        event.preventDefault();
                           
        masks.hammerTime = 'yyyy-mm-dd';

        setFire(true);
                       
        try{
            await loginSchema.validate(courseDtls, { abortEarly: false })

            if(!props.editing){
                if(props.user==='TCH'){
                    var tchId=props.makerId
                    var tchMaker=props.makerId
                    var schMaker="NULL"
                }
                else{
                    var tchId=courseDtls.tchId
                    var tchMaker="NULL"
                    var schMaker=props.makerId           
                }
                
                try {

                    const msg = await newCourse(
                        props.user,
                        tchMaker,
                        schMaker,
                        tchId,
                        courseDtls.subId,
                        courseDtls.catId,
                        dateFormat(courseDtls.date, "hammerTime"),
                        courseDtls.price,
                        courseDtls.name,
                        courseDtls.mode                
                    );
                                         
                    props.refreshPage();
                    alert(msg,'SUCCESS');
                } catch (error) {                    
                    alert(error.message,'ERROR');
                }                                
            }
            else {
                try{                   
                    const msg=await updateCourse(
                        props.user,
                        courseDtls.tchId,
                        courseDtls.subId,
                        courseDtls.catId,
                        dateFormat(courseDtls.date, "hammerTime"),
                        courseDtls.price,
                        courseDtls.name,
                        props.code                
                    )
                    
                    props.updateCourseDtls(courseDtls.name,courseDtls.teacher,dateFormat(courseDtls.date, "hammerTime"))                    
                    alert(msg,'SUCCESS')
                    
                } catch (error) {                    
                    alert(error.message,'ERROR');
                }  
            }
                                                                       
        }catch (err) {
      
            setErrors(err.inner.reduce((acc, { path, message }) => {
              acc[path] = message;
              return acc;
            }, {}));
        } finally {
            setFire(false);
        }
                
    }
//-------------------------------------------------------------------------------------------

    const openCourseModal=()=>{ 
      setShow(true);
    }
//-------------------------------------------------------------------------------------------

    const closeCourseModal=()=>{ 
        
        setShow(false);
        
        setCourseDtls({
            price:"",        
            name:"",
            subId:0,
            catId:0,
            tchId:0,
            mode:"GROUP",
            date:addDays(new Date(), 1)
        })

        setErrors({})

        if(!props.link)
            props.resetMenu(0);
    }
//-------------------------------------------------------------------------------------------

    const getSubject = useCallback((sub) => {
        setCourseDtls(prevState => ({
            ...prevState,["subId"]: sub
        }))
    }, [setCourseDtls])
//-------------------------------------------------------------------------------------------

    const getCategory = useCallback((cat) => {
        setCourseDtls(prevState => ({
            ...prevState,["catId"]: cat
        }))
    }, [setCourseDtls])
//-------------------------------------------------------------------------------------------
    
    const getTeacher = useCallback((id,teacher) => {
        setCourseDtls(prevState => ({
            ...prevState,["tchId"]: id,
            ["teacher"]:teacher
        }))
    }, [setCourseDtls])
//-------------------------------------------------------------------------------------------

    const getCourseMode = useCallback((mode) => {
        setCourseDtls(prevState => ({
            ...prevState,["mode"]: mode,
            ["date"]:addDays(new Date(), 1)
        }))
        setErrors({})
    }, [setCourseDtls])    
//-------------------------------------------------------------------------------------------

    return (  
        <div>                               
            {props.link?
                <>
                 {props.enabled?
                    <Button variant="contained" color="success" size="small" onClick={openCourseModal}>יצירת קורס</Button>:                    
                    <Button variant="contained" color="success" size="small" disabled>יצירת קורס</Button>
                 }
                </>:
                null
            }
            <Modal 
              show={show} 
              onHide={closeCourseModal} 
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"           
             >  

                <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><FaChalkboardTeacher size={30} /></div>
                        <div className="mt-1">
                            <h5>{props.editing ? 'עריכת קורס' : 'קורס חדש'}</h5>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="custom-modal-close"
                        aria-label="Close"
                        onClick={closeCourseModal}
                    >
                        &times;  {/* Close "X" icon */}
                    </button>
                </Modal.Header>  
                
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div  className="container">

                            {fire?
                                <div 
                                    className="position-absolute top-50 start-50 translate-middle-x" 
                                    style={{zIndex: "1",top:"300",left:'300'}}
                                >
                                    <ReactLoading 
                                        type="spin" 
                                        color="rgb(53, 126, 221)" 
                                        height={70} 
                                        width={70}
                                    />
                                </div>
                                :
                                null
                            }
                            
                            <div className="mt-3">
                                <CourseMode setCourseMode={getCourseMode} mode={courseDtls.mode} editing={props.editing}/>
                            </div> 
                            
                            <div class="d-flex flex-wrap gap-3 mt-5">
                                                                                                                                                    
                                {
                                    courseDtls.mode==="GROUP"?
                                        <div className="label-100-wrap">                    
                                            <label className="form-label label-100" for="L-date">תאריך התחלה</label>
                                            <div id="L-date" dir="ltr">                                                          
                                                <DatePicker 
                                                    wrapperClassName="date-picker"  
                                                    selected={courseDtls.date} 
                                                    onChange={(date)=>{
                                                        setCourseDtls(prevState => ({
                                                            ...prevState,
                                                            date: date
                                                        }));
                                                    }} 
                                                    popperPlacement="bottom-end"
                                                    minDate={addDays(new Date(), 1)}
                                                    dateFormat="yyyy-MM-dd" 
                                                    disabled={courseDtls.date && courseDtls.date <= new Date()} 
                                                />                      
                                            </div>
                                            {errors.date && <p className="error">{errors.date}</p>}
                                        </div>
                                    :null
                                } 
                                                                
                                <div className="label-100-wrap">
                                    <label class="form-label label-100" for="subjects">מקצוע</label>              
                                    <div id="subjects">
                                       <SelectSubject 
                                            setSubject={getSubject} 
                                            editing={props.editing} 
                                            subId={courseDtls.subId} 
                                            alert={alert}
                                        />
                                    </div>
                                </div>

                                <div className="label-100-wrap">
                                    <label class="form-label label-100" for="catigories">קטגוריה</label>              
                                    <div id="catigories">
                                        <SelectCategory 
                                            setCategory={getCategory} 
                                            catId={courseDtls.catId} 
                                            editing={props.editing}
                                            alert={alert} 
                                        /> 
                                    </div>
                                </div>
                                {props.user==='SCH'?
                                    <div className="label-100-wrap">
                                        <label class="form-label label-100" for="teacher">מורה</label>              
                                        <div id="teacher">
                                            <SelectTeacher 
                                                setTeacher={getTeacher} 
                                                centerId={props.makerId}
                                                tchId={courseDtls.tchId}
                                                alert={alert}
                                            />
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                                                                                                            
                            </div> 
                            
                            <div className="label-100-wrap mt-5">                
                                <label className="form-label label-100" for="name">שם הקורס</label>
                                <div>
                                    <input  
                                        type="text" 
                                        className={`form-control ${errors.name? 'invalid' : ''}`} 
                                        name="name" 
                                        value={courseDtls.name} 
                                        onChange={handleChange} 
                                        placeholder="שם קורס"
                                    /> 
                                    {errors.name && <p className="error">{errors.name}</p>}
                                </div>   
                            </div> 

                            <div className="label-100-wrap mt-5 mb-3">                
                                <label className="form-label label-100" for="price">מחיר לשיעור</label>
                                <div>
                                    <input  
                                        type="text" 
                                        className={`form-control ${errors.price? 'invalid' : ''}`} 
                                        name="price" 
                                        value={courseDtls.price} 
                                        onChange={handleChange}             
                                        style={{width:"100px"}}
                                    />
                                    {errors.price && <p className="error">{errors.price}</p>}
                                </div>   
                            </div>                         
                                                           
                        </div>
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3 me-3 mb-2">            
                            <Button 
                                variant="contained"  
                                color="error"  size="small" 
                                onClick={closeCourseModal}
                                disabled={fire}
                            >
                                ביטול
                            </Button>
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="success" 
                                size="small"
                                disabled={fire}
                            >
                                שמירה
                            </Button>
                        </div>                                                                          
                    </Modal.Footer> 
                </form>        
            </Modal>  
        </div>        
    )  
  } 
  
export default CourseModal;  
