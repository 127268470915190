import React from "react";

const ZoomRecordingsPlayerWithPopup = ({ recordings }) => {
  const playRecordingsInPopup = () => {
    if (recordings.length === 0) {
      alert("No recordings available.");
      return;
    }

    // Open a new popup window
    const popup = window.open(
      "",
      "VideoPlayerPopup",
      "width=800,height=600,scrollbars=no,resizable=yes"
    );

    if (!popup) {
      alert("Popup blocked! Please allow popups for this site.");
      return;
    }

    popup.document.title = "Lesson Recording Playback";

    
    const popupContent =`
      <html>
      <head>
        <style>
          body {
            margin: 0;
            background-color: #000;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100vh;
          }

          video {
            width: 100%;
            height: calc(100% - 50px);
            background-color: #000;
          }

        </style>
      </head>
      <body>
        <video id="videoPlayer" controls controlslist="nodownload"></video>
      </body>
      </html>
    `;

    // Append the content to the popup window
    popup.document.body.innerHTML = popupContent;

    // Add functionality to play videos sequentially
    const videoPlayer = popup.document.getElementById("videoPlayer");
    let currentIndex = 0;

    const playNextVideo = () => {
      if (currentIndex < recordings.length) {
        videoPlayer.src = recordings[currentIndex];
        videoPlayer.play();
        currentIndex++;
      } else {
        alert("All recordings have been played.");
        popup.close();
      }
    };

    videoPlayer.addEventListener("ended", playNextVideo);

    
    // Start playing the first video
    playNextVideo();
  };

  return (
    <div>
      <button onClick={playRecordingsInPopup} class="btn  btn-success btn-sm start-lesson-btn">
          צפייה בשיעור  
      </button>
    </div>
  );
};

export default ZoomRecordingsPlayerWithPopup;
